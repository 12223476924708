import React from "react";

import { Icon } from '@types';

const WeChat: Icon = ({ fill = "white" }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 1000 1000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill={fill}><path d="M746.2,485.3c-13.8-4.1-23.2-6.9-16-25c15.6-39.2,17.3-73.1,0.3-97.3c-31.8-45.3-118.9-43-218.6-1.2c0,0-31.3,13.7-23.4-11.2c15.3-49.2,13-90.4-10.9-114.3c-54.2-54-198,2-321.5,125.1C63.7,453.9,10,551.5,10,635.9c0,161.7,207.8,259.9,411.1,259.9c266.5,0,443.8-154.4,443.8-277.1C864.9,544.7,802.4,502.8,746.2,485.3L746.2,485.3z M421.7,837.9c-162.2,16-302.3-57.1-312.8-163.5C98.4,568.3,221.4,469.2,383.7,453.2c162.3-16,302.3,57.1,312.8,163.3C706.9,722.9,583.9,822,421.7,837.9L421.7,837.9z"/><path d="M923.2,189.5c-64.4-71.1-159.4-98.3-246.9-79.7l0,0c-20.2,4.3-33.3,24.2-28.8,44.4c4.3,20.2,24.2,33.1,44.6,28.8c62.4-13.2,129.7,6.1,175.5,56.6c45.8,50.5,58.1,119.5,38.5,179.9l0,0c-6.4,19.8,4.4,40.8,24.2,47.1c19.8,6.4,40.8-4.4,47.2-24v-0.2C1004.8,357.6,987.6,260.6,923.2,189.5L923.2,189.5z"/><path d="M824.2,278.5c-31.3-34.7-77.5-47.9-120.3-38.9c-17.5,3.8-28.6,20.9-24.9,38.2c3.8,17.3,20.9,28.5,38.4,24.7l0,0c20.9-4.4,43.5,2,58.8,18.9c15.3,17,19.4,40,12.8,60.3l0,0c-5.4,17,3.8,35.1,20.7,40.5c17,5.4,35.2-3.8,40.7-20.7C864.1,360.4,855.7,313.1,824.2,278.5L824.2,278.5z"/><path d="M437.9,535c-77.2-20.1-164.5,18.3-198,86.3c-34.1,69.1-1.2,146,76.9,171c80.8,26,176-13.8,209.1-88.6C558.5,630.8,517.7,555.6,437.9,535L437.9,535z M378.9,711.8c-15.6,25-49.2,35.9-74.6,24.4c-25-11.4-32.3-40.3-16.6-64.7c15.5-24.2,47.9-35.1,73.1-24.5C386.2,657.7,394.4,686.5,378.9,711.8L378.9,711.8z M430.6,645.7c-5.6,9.7-18.3,14.3-28,10.4c-9.7-4-12.7-14.8-7.2-24.4c5.6-9.4,17.8-14.2,27.3-10.2C432.6,624.9,436.1,635.8,430.6,645.7L430.6,645.7z"/></g>

    </svg>
);

export default WeChat;
